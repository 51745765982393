/*CORE*/
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { Button, Form, message, Switch, Typography } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
/*COMPONENTS*/
import AppIdHeader from '../../../../MiniApps/components/AppIdHeader/AppIdHeader'
import InfoTooltip from '../../../../../common/InfoTooltip'
import Badge from '../../../../components/Badge/Badge'
/*CONSTANTS*/
import { DOCS_URL } from 'utils/constants'
/*TYPES*/
import { IPartnershipDetails } from 'types/Partnerships'
/*STYLES*/
import styles from './PartnerIntegration.module.scss'
/*SELECTORS*/
import { updatePartnership } from '../../../../../../store/partnerships/actions'
import FormItem from 'antd/lib/form/FormItem'

const { Title } = Typography

function FormLabel({ label, tooltip }: { label: string; tooltip?: string }) {
  return (
    <div className={styles['inherit-colors']}>
      <span className={styles['label-tooltip']}>
        {label}
        <InfoTooltip title={tooltip} />
      </span>
    </div>
  )
}

function Section({ children }: PropsWithChildren<any>) {
  return (
    <section className={styles['partner-integration__section']}>
      {children}
    </section>
  )
}

interface Props extends ConnectedProps<typeof connector> {
  partnership: IPartnershipDetails
}

const REQUIRED_FIELDS_EXAMPLES = {
  name: 'Bob',
  email: 'user@example.com',
  phone: '+65 1111 1111',
}

const PartnerIntegration = ({ partnership, updatePartnership }: Props) => {
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation().pathname.split('/')
  const partnershipId = +location[location.length - 2]
  const initialValues = useMemo(() => {
    const { single_sign_on_enabled, payments_enabled, is_consent_enabled } =
      partnership
    return {
      single_sign_on_enabled,
      payments_enabled,
      is_consent_enabled,
    }
  }, [partnership])
  const [formValues, setFormValues] = useState(() => initialValues)

  const onSubmit = (data: any) => {
    setIsLoading(true)
    updatePartnership(partnershipId, data).then(() => {
      setIsLoading(false)
      message.success('Successfully saved')
    })
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={onSubmit}
      initialValues={initialValues}
      onValuesChange={(_, values: any) => setFormValues(values)}
    >
      <div className={styles['partner-integration']}>
        <Section>
          <Title level={3}>App ID</Title>
          <div className={styles['partner-integration__header']}>
            <AppIdHeader
              appId={partnership.miniapp.app_id}
              description={`Use the following App ID to launch ${partnership.miniapp.name}:`}
            />
            <div>
              <Button
                type="primary"
                htmlType="submit"
                size="small"
                loading={isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </Section>
        <Section>
          <Title level={3}>White-labeling</Title>
          <FormItem
            label={
              <FormLabel
                label="Inherit hostapp colors"
                tooltip="When turned on, host app theming colors will be passed to miniapp instead"
              />
            }
            colon={false}
          >
            <Badge
              status={partnership.hostapp.share_colors ? 'success' : 'warning'}
              label={partnership.hostapp.share_colors ? 'Enabled' : 'Disabled'}
            />
          </FormItem>
        </Section>
        <Section>
          <FormItem
            name="single_sign_on_enabled"
            label={<Title level={3}>Boxo connect</Title>}
            valuePropName="checked"
            colon={false}
          >
            <Switch />
          </FormItem>
          {formValues.single_sign_on_enabled && (
            <FormItem
              name="is_consent_enabled"
              label={
                <FormLabel
                  label="Show user consent modal"
                  tooltip="This screen allows the user to explicitly consent to sharing their data between the host app and the miniapp"
                />
              }
              colon={false}
              valuePropName="checked"
            >
              <Switch />
            </FormItem>
          )}
        </Section>
        <Section>
          <FormItem
            name="payments_enabled"
            label={<Title level={3}>Payments</Title>}
            valuePropName="checked"
            colon={false}
          >
            <Switch />
          </FormItem>
        </Section>
        {partnership.miniapp.required_fields.length > 0 && (
          <Section>
            <Title level={3}>Boxo Connect</Title>
            <div style={{ marginBottom: 30 }}>
              <a
                href={`${DOCS_URL}/hostapp/boxo-connect.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Boxo Connect
              </a>{' '}
              is a set of functions and classes that allows authorization of the
              host user in the miniapp.
            </div>
            <div>
              <span className={styles['label-tooltip']}>
                Required fields
                <InfoTooltip title="Minimum required user detail fields to create an account within this miniapp" />
              </span>
              <div className={styles['required-fields']}>
                {partnership.miniapp.required_fields.map(
                  (requiredField, index) => (
                    <span key={index}>{requiredField}</span>
                  )
                )}
              </div>
              <pre className={styles['required-fields-snippet']}>
                <code>
                  <span className={styles['punctuation']}>{'{'}</span>
                  <br />
                  {partnership.miniapp.required_fields.map((field, i) => (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className={styles['property']}>{field}</span>
                      <span className={styles['operator']}>:</span>
                      &nbsp;
                      <span className={styles['string']}>
                        "
                        {
                          REQUIRED_FIELDS_EXAMPLES[
                            field as 'name' | 'email' | 'phone'
                          ]
                        }
                        "
                      </span>
                      {i < partnership.miniapp.required_fields.length - 1 && (
                        <span className={styles['punctuation']}>,</span>
                      )}
                      <br />
                    </>
                  ))}
                  <span className={styles['punctuation']}>{'}'}</span>
                </code>
              </pre>
            </div>
          </Section>
        )}
      </div>
    </Form>
  )
}

/* Redux */
const connector = connect(undefined, { updatePartnership })
export default connector(PartnerIntegration)
